import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(4)
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(6),
            display: 'flex',
            justifyContent: 'center'
        }
    },
    socialMediaButton: {
        lineHeight: '30px',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        opacity: '1',
        color: '#6B708F',
        transition: '0.3s',
        "&:hover": {
            opacity: '0.6'
        },
        float: 'left'
    },
    socialImage: {
        paddingRight: '10px',
        height: '27px'
    },
    share: {
        '&:not(:last-child)': {
            marginRight: theme.spacing(8)
        }
    },
}));

const Share = () => {
    const classes = useStyles();
    const title = typeof document !== 'undefined' ? document.title : '';
    const url = typeof document !== 'undefined' ? document.URL : ''

    return (
        <Grid xs={12} container item className={classes.root}>
            <Grid className={classes.share}>
                <a
                    href={`https://www.facebook.com/share.php?u=${url}&quote=${title}&hashtag=${"%23onlinemoneytransfer"}`}
                    target="_blank"
                    className={classes.socialMediaButton}>
                    <img className={classes.socialImage} src='/img/share/facebook.svg' alt={'Share on Facebook'} />
                    {`Share`}
                </a>
            </Grid>
            <Grid className={classes.share}>
                <a
                    href={`https://twitter.com/share?url=${url}&text=${title}&via=Remitbee&hashtags=onlinemoneytransfer,remitbee`}
                    target="_blank"
                    className={classes.socialMediaButton}>
                    <img className={classes.socialImage} src='/img/share/twitter.svg' alt={'Tweet on Twitter'} />
                    {`Tweet`}
                </a>
            </Grid>
            {/*<Grid className={classes.share}>
                <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&summary=Remitbee&title=${title}&url=${url}`}
                    target="_blank"
                    className={classes.socialMediaButton}>
                    <img className={classes.socialImage} src='/img/share/linkedin.svg' alt={'Publish on LinkedIn'} />
                    {`Share`}
                </a>
    </Grid>*/}
        </Grid>
    );
};

export default Share;
