import { Fade, FormControl, Popper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { bool, func } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import FlagIcon from '../FlagIcon';
import CountrySelectionDropdown from '../countrySelectionDropbox/CountrySelectionDropdown';
import NumberFormat from '../NumberFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    border: '1px solid #CACCD5',
    boxShadow: 'none',
    height: '69px',
    borderRadius: '5px'
  },
  formControl: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    flex: 1,
    color: theme.palette.primary.dark,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.up('xs')]: {
      fontSize: '22px',
      lineHeight: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '26px',
    }
  },
  inputLabelShrink: {
    fontSize: '12px',
    marginTop: theme.spacing(1),
    color: '#6B708F'
  },
  iconButton: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    minWidth: '120px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconContentStyle: {
    marginRight: '0.4em',
  },
  labelContentStyle: {
    marginTop: '-0.45em',
    marginRight: '0.6em',
  },
  paper: {
    borderRadius: 5,
    border: '1px solid #CACCD5',
    overflow: 'hidden'
  },
  skeleton: {
    backgroundColor: 'lightgray',
    marginRight: theme.spacing(2)
  },
  loading: {
    color: '#acb6c8',
    fontSize: '22px',
    lineHeight: '32px'
  }
}));

function CurrencyInput({
  countriesList,
  defaultCountryCurrency,
  defaultCountryCode,
  selectable,
  selected,
  selectedCurrency,
  onSelectChange,
  editable,
  value,
  onValueChange,
  label,
  eraseOnFocus,
  onBlur,
  inputId,
  autoFocus,
  precision,
  doAlert,
  AlertInfo,
  loading,
  min,
  max
}) {
  const classes = useStyles();
  const [open] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [countrySelected, setCountrySelected] = useState();
  const [validatePrecision, setValidatePrecision] = useState(2);
  const inputRef = useRef();
  const [dropDownWidth,setDropDownWidth] = useState()
  let computedStyle
  if(inputRef?.current) {
    computedStyle = window.getComputedStyle(inputRef.current);
  }

  useEffect(() => {
    if (precision) setValidatePrecision(+precision);
  }, [precision]);

  useEffect(() => {
    if (countriesList && countriesList.length) {
      if (selected && !selectedCurrency) setCountrySelected(countriesList.find((country) => country.country_id == selected || country.country_to_id == selected));
      else if (selected && selectedCurrency) setCountrySelected(countriesList.find((country) => (country.country_id == selected || country.country_to_id == selected) && (country.currency_code === selectedCurrency)));
    }
  }, [selected, selectedCurrency, countriesList]);

  useEffect(() => {
    if (onSelectChange && typeof countrySelected === 'object') onSelectChange(countrySelected);
  }, [countrySelected]);

  useEffect(() => {
    /* Sets dynamic width for the dropdown */
    if (computedStyle) setDropDownWidth(computedStyle.width);
  }, [inputRef, computedStyle?.width]);

    useEffect(() => {
      /* Closes dropdown whenever user clicks on the screen */
      const enableAutoClose = (e) => {
        let element = e.target;
        if(element && element?.id?.includes("request_country")) return;
        if(element?.tagName?.includes("INPUT") || element?.tagName?.includes("BUTTON") || element?.innerHTML?.includes("request") || element?.innerHTML?.includes("Submit")) return;
        handleClick(e)
      }
      if (anchorEl && document) {
        document.addEventListener('mousedown', enableAutoClose);

        return () => {
          document.removeEventListener('mousedown', enableAutoClose);
        };
      }
    }, [anchorEl]);

  const handleMenuItemClick = (country) => {
    setCountrySelected(country);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    const target = event.currentTarget;
    setTimeout(() => {
      setAnchorEl(anchorEl ? null : target);
    });
  };

  const handleChange = (changedValue) => {
    onValueChange(changedValue);
  };

  // const handleClose = () => {
  //   if (anchorEl) setAnchorEl(null);
  // };

  return (
    <div>
      <Paper className={classes.root} elevation={0} ref={inputRef}>
        <FormControl className={classes.formControl}>
          <Typography htmlFor={inputId} classes={{ root: classes.inputLabelShrink }}>
            {label}
          </Typography>
          <Typography style={{ display: loading ? 'inline-block' : 'none' }} className={classes.loading}>Loading...</Typography>
          <InputBase
            aria-label="Amount"
            id={inputId}
            classes={{ input: classes.input }}
            style={{ display: !loading ? 'inline-block' : 'none' }}
            value={value}
            autoFocus={autoFocus === false ? false : inputId == 'input1' && value === '' ? true : false}
            placeholder={'0.00'}
            readOnly={editable ? false : true}
            inputProps={{ decimalScale: validatePrecision, thousandSeparator: true }}
            inputComponent={NumberFormat}
            onFocus={() => eraseOnFocus && value === '0.00' && handleChange('')}
            onChange={(e) => (editable ? handleChange(e.target.value) : null)}
            onBlur={onBlur}
          ></InputBase>
        </FormControl>

        <Button
          id='select-amount-currency-input'
          color="primary"
          className={classes.iconButton}
          aria-label="Directions"
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={selectable ? handleClick : null}
          disableRipple={true}
        >
          <FlagIcon
            code={countrySelected?.iso2 || defaultCountryCode}
            className={classes.iconContentStyle}
          />

          {countrySelected?.currency_code || defaultCountryCurrency}
          {selectable && Boolean(anchorEl) !== true && <ExpandMore />}
          {Boolean(anchorEl) && <ExpandLess />}
        </Button>

      </Paper>
      {selectable && (
        <Popper
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
            },
          }}
          disablePortal={true}
          placement="bottom-end"
          style={{ zIndex: 1300, width: dropDownWidth }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Paper elevation={4} style={{width: dropDownWidth ? `${dropDownWidth} !important` : 'inherit'}} className={classes.paper}>
                {countriesList && (
                  <CountrySelectionDropdown
                    onChange={(country) => handleMenuItemClick(country)}
                    isDropdown={false}
                    countriesList={countriesList}
                    searchTextOptions={[
                      'currency_code',
                      'currency_name',
                      'country_to',
                      'country_name',
                    ]}
                  />
                )}
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
      {doAlert && <AlertInfo min={min} max={max} unit={defaultCountryCode}/>}
      </div>
  );
}
CurrencyInput.propTypes = {
  onSelectHandler: func,
  selectable: bool,
  editable: bool,
  eraseOnFocus: bool,
};

CurrencyInput.defaultProps = {
  countriesList: [],
  eraseOnFocus: false,
};
export default CurrencyInput;
